.separator:after {
  padding-top: 50px;
  content: "⠿";
  color: #999;
  display: flex;
  align-items: top;
  justify-content: flex-end;
  height: 100%;
}

.ant-menu {
  margin-top: 20px !important;
  padding-right: 10px !important;
}

.sidebar {
  background-color: #fff;
  position: fixed;
}

.stage .sidebar .navbar-item {
  background-color: #fed203;
}

.stage .sidebar .navbar-item::after {
  content: "STAGE";
  left: 10px;
  position: absolute;
  font-weight: bold;
  background-color: #ffffff88;
  color: #b50018;
}

ul.ant-menu {
  margin-top: 0px !important;
}

.column.main-content {
  padding-top: 0px;
}

.guillotina {
  padding-top: 25px;
}

.ant-menu-item {
  height: 30px !important;
  line-height: 30px !important;
}

.footer-content {
  text-align: center;
  color: #7b7a7a;
  font-size: 12px;
}

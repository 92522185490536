
body
  height: auto !important
  background-color: #f3f3f3 !important


.section.main
  .container
    max-width: 100%

.main-panel
  min-height: 80vh


.background-color-yellow
  background: rgba(255, 255, 0, 0.2)

.background-color-red
  background: rgba(255, 0, 0, 0.2)

.background-color-green
  background: rgba(0, 128, 0, 0.2)

.background-color-orange
  background: rgba(243, 167, 67, 0.2)


.select-options-all-text .ant-select-item-option-content
    white-space: unset
    text-overflow: unset
    overflow: unset
  
p
  margin: 0